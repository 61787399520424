.video{
    height: 100%;
    width: 100%;
}

.video video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: flex;
    border-radius: 12px;
    border: none;
    background-color: #000;
}


.postion-top{
   position: absolute;
   top: 30px;
   width: 80%;
   left: 50%;
   transform: translate(-50%, 0px);
}

.alignment{
   justify-content: space-between;
}

@media (max-width: 991px){
   .hide{
      display: none;
   }
   .alignment{
      justify-content: center;
      flex-flow: column;
   }
}
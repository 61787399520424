.footer-icons{
    font-size: 25px;
    transition: .5s all;
    color: #fff !important;
    border-bottom: 2px solid transparent;
}

.footer-icons:hover{
    border-color: var(--brand-color);
}

@media (max-width: 500px){
    .text-mbl-center{
        text-align: center;
    }
}
.bg {
    background-color: var(--dark-color);
    padding: 50px 0;
  }
  
  
  .service-card {
    height: 100%;
    transition: .5s all;
    position: relative;
    overflow: hidden;
  }

  .service-card::before{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0px;
    height: 5px;
    width: 0;
    background-color: var(--brand-color);
    opacity: 0;
    visibility: hidden;
    transition: .5s all;
  }

  .service-card:hover::before{
    opacity: 1;
    width: 100%;
    visibility: visible;
  }
  


.banner{
    background-image: url(../../../../public/assets/banner.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 200px 200px 200px 200px;
    position: relative;
    z-index: 1;
}

.top-title{
    font-size: 60px !important;
    font-weight: 900 !important;
    line-height: 70px !important;
    letter-spacing: -2px;
    font-style: italic;
}

.overlay{
    background-image: url(../../../../public/assets/overlay.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.76;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}

@media (max-width: 1199px){
    .banner{
        padding: 150px 10px 30px 10px;
    }
    .top-title{
        font-size: 30px !important;
        line-height: 30px !important;
    }
}
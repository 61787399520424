.f-weight-normal{
    font-weight: 400;
}
.gray-sm-text{
    color: #B4B4B6 !important;
}

.select-cs input{
    height: 40px !important;
    font-size: 16px;
}

.select-cs .ant-select-selection-placeholder{
    line-height: 40px !important;
}

.select-cs .ant-select-selector{
    height: 40px !important;
    border: 0px !important;
}
.border-0-bottom{
    border: 0px !important;
    border-bottom: 1px solid #B4B4B6 !important;
}

.block-border{
    border: 1px dashed #2E2E2E;
    padding: 20px;
    flex-basis: 48%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    gap: 13px;
    text-align: center;
}

.block-border>span{
    font-size: 14px !important;
    color: #919191 !important;
}

.c-button{
    padding: 14px 28px !important;
    max-width: 120px;
    width: 100%;
    display: flex !important;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: #fff !important;
    border: 1px solid var(--brand-color) !important;
    height: 40px !important;
    color: var(--brand-color) !important;
}

.list-circle{
    padding-left: 10px;
}

.list-circle ul{
    list-style: none;
    border-left: 1px solid gray;
    padding-left: 32px;
}

.list-circle ul li{
    margin-bottom: 40px;
    position: relative;
}

.list-circle ul li .circle-count{
    position: absolute;
    border: 1px solid #2e2e2e;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    font-weight: 700;
    font-size: 18px;
    left: -52px;
    top: 0px;
    background-color: #F5F5F7;
}

.card-block{
    max-width: 540px;
    width: 100%;
    position: relative;
}

.card-block>.ant-card-body{
    padding: 20px 44px 40px 50px;
    background-color: #F5F5F7;
}

.card-block::after{
    content: '';
    z-index: -1;
    position: absolute;
    top: 20px;
    left: 20px;
    width: 100%;
    height: 100%;
    border: 1px dashed var(--brand-color);
}

.border-0-bottom{
    border-radius: 0px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
}

@media (max-width: 991px){
    .card-block{
        margin-top: 0px !important;
    }
    .card-block::after{
        top: 12px;
        left: 12px;
    }
}